body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  background: rgb(224, 92, 22);
  background: linear-gradient(
    135deg,
    rgba(224, 92, 22, 1) 0%,
    rgba(214, 33, 180, 1) 100%
  );
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

h1.MainText {
  font-family: "Pacifico", cursive;
  font-size: 64px;
  height: 110px;
  margin: 0;
  padding: 0;
  margin-top: 40px;
  background: #3d3d3d;
  background: linear-gradient(to top, #3d3d3d 19%, #878787 50%, #3d3d3d 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  -webkit-transform: rotate(-5deg);

          transform: rotate(-5deg);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: rgb(224, 92, 22);
  background: linear-gradient(
    135deg,
    rgba(224, 92, 22, 1) 0%,
    rgba(214, 33, 180, 1) 100%
  );
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

div.noticebg {
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url(/static/media/seized.efc7fc1d.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

